<div class="row" *ngIf="!isLoading">
    <div class="col text-center mt-5" *ngIf="isVerified">
        <h1>Verify Email</h1>
        <p>Your email has been verified!</p>
    </div>

    <div class="col text-center mt-5" *ngIf="!isVerified">
        <h1>Verify Email</h1>
        <p>Unable to verify your email, please click <a href="/settings">here</a> and confirm your email address</p>
    </div>
</div>