import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCardComponent } from './product-card/product-card.component';
import { SearchComponent } from './search/search.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FormsModule } from '@angular/forms';
import { ProductModalComponent } from './product-modal/product-modal.component';

@NgModule({
  declarations: [
    PageHeaderComponent,
    SearchComponent,
    ProductCardComponent,
    ProductDetailComponent,
    ProductModalComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    MatIconModule,
    NgbModule,
    NgbCollapseModule,
    NgApexchartsModule,
    FormsModule
  ],
  exports: [
    PageHeaderComponent,
    SearchComponent,
    ProductCardComponent,
    ProductDetailComponent,
    ProductModalComponent
  ],
})
export class SharedModule { }
