import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductDisplayDto } from 'src/app/core/services/client.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() product: ProductDisplayDto | undefined;
  @Output() productClickEvent = new EventEmitter<ProductDisplayDto | undefined>();

  ngOnInit(): void {
  }

  hasIncreased(number: string | undefined): boolean {
    return number?.includes('+') ? true : false;
  }

  productClick() {
    this.productClickEvent.emit(this.product)
  }
}
