import { Injectable } from '@angular/core';
import { Client } from './client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StoresService {
  private client: Client;

  constructor() {
    this.client = new Client(environment.apiUrl);
  }

  async getStores() {
    var stores = await this.client.getStores();
    return stores;
  }
}
