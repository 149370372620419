import { Injectable } from '@angular/core';
import { Client, Sort } from './client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private client: Client;

  constructor() {
    this.client = new Client(environment.apiUrl);
  }

  async searchProducts(search: string| undefined, page: number, take: number, store: number | undefined, sort: Sort | undefined) {
    var products = (await this.client.search(search, page, take, store, sort)).products;
    return products;
  }

  async getProduct(productId: string | undefined, store: string | undefined) {
    var productHistory = (await this.client.productsGET(productId, store));
    return productHistory;
  }
}
