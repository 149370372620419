import { Component, Input, OnInit, ViewChild, OnChanges, ElementRef, HostListener } from '@angular/core';
import { PriceHistory, PriceStat, ProductDisplayDto, ProductHistoryDto } from 'src/app/core/services/client.service';
import { ProductsService } from 'src/app/core/services/products.service';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTooltip,
  ApexStroke,
  ApexAnnotations,
  ApexYAxis
} from "ng-apexcharts";
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationTypes } from 'src/app/types/notificationTypes';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
};

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})

export class ProductDetailComponent implements OnChanges {

  // Inputs
  @Input() productId: string | undefined = undefined;
  @Input() store: string | undefined = undefined;
  @Input() userId: string | undefined = undefined;
  @Input() token: string | undefined = undefined;
  @Input() showSettings: boolean = false;
  showListPrice: boolean = true;

  // Apex chart
  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;

  // Chart data
  productHistory: ProductHistoryDto | undefined;

  // Stats
  current: PriceStat | undefined = undefined;
  lowest: PriceStat | undefined = undefined;
  average: PriceStat | undefined = undefined;
  highest: PriceStat | undefined = undefined;

  // Page variables
  isLoading: boolean = true;
  showReload: boolean = false;

  // Notifications
  settings: any = null;
  anyPrice: boolean = false;
  priceUp: boolean = false;
  priceDown: boolean = false;
  lowestPrice: boolean = false;
  stockChange: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private productService: ProductsService, private notificationService: NotificationsService, private elementRef: ElementRef) {
  }

  ngOnChanges(): void {
    // Get user settings
    let settingsJson = window.localStorage.getItem('settings');
    if (settingsJson) {
      this.settings = JSON.parse(settingsJson);
      this.userId = this.settings?.userId;

      this.showSettings = this.userId == null;
    }
    else {
      this.route.queryParamMap.subscribe((params) => {
        this.userId = params.get('userId') ?? undefined;
      });

      if (!this.userId) {
        this.showSettings = true;
      }
    }

    if (this.productId && this.store) {
      // Get product history and draw chart
      this.getProductHistory();

      // Send iframe size to extension after short delay
      setTimeout(() => {
        this.sendIframeSize();
      }, 1000);

      // Load user notification settings
      if (this.userId) {
        this.loadNotificationSettings();
      }
    }
  }


  gotoSettings() {
    this.showReload = true;
    window.open('/settings', '_blank');
  }

  reload() {
    const url = new URL(window.location.href);
    if (url.searchParams.has('settings')) {
      url.searchParams.delete('settings');
    }
    if (url.searchParams.has('uid')) {
      url.searchParams.delete('uid');
    }
    window.location.href = url.toString();
  }


  async getProductHistory() {
    this.isLoading = true;
    this.productHistory = await this.productService.getProduct(this.productId, this.store);
    this.drawPriceChart();
    this.isLoading = false;
    // Set stats variables
    this.current = this.productHistory?.priceStats?.find(x => x.title == 'Current');
    this.highest = this.productHistory?.priceStats?.find(x => x.title == 'Highest');
    this.average = this.productHistory?.priceStats?.find(x => x.title == 'Average');
    this.lowest = this.productHistory?.priceStats?.find(x => x.title == 'Lowest');
  }

  drawPriceChart() {
    var priceSeries: any = this.productHistory?.prices?.map(p => [p.date, p.price]);
    var listingPriceSeries: any = this.productHistory?.prices?.map(p => [p.date, p.listingPrice]);
    var lowest = this.productHistory?.priceStats?.find(x => x.title == 'Lowest');
    var highest = this.productHistory?.priceStats?.find(x => x.title == 'Highest');
    var average = this.productHistory?.priceStats?.find(x => x.title == 'Average');

    var lowAnnotation = {
      y: lowest?.amount,
      borderColor: '#00E396',
      label: {
        borderColor: '#00E396',
        style: {
          color: '#fff',
          background: '#00E396'
        },
        text: 'lowest'
      }
    };
    var highAnnotation = {
      y: highest?.amount,
      borderColor: '#d52c42',
      label: {
        borderColor: '#d52c42',
        style: {
          color: '#fff',
          background: '#d52c42'
        },
        text: 'highest'
      }
    };
    var averageAnnotation = {
      y: average?.amount,
      borderColor: '#f86201',
      label: {
        borderColor: '#f86201',
        style: {
          color: '#fff',
          background: '#f86201'
        },
        text: 'average'
      }
    }

    this.chartOptions = {
      series: [
        {
          name: 'Listing Price',
          data: listingPriceSeries,
          color: '#f7b028'
        },
        {
          name: 'Price',
          data: priceSeries,
          color: '#378ffa'
        },
      ],
      chart: {
        type: 'area',
        animations: {
          enabled: false
        },
        stacked: false,
        height: 250,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom'
        }
      },
      stroke: {
        curve: 'stepline'
      },
      annotations: {
        yaxis: [
          lowAnnotation,
          highAnnotation,
          averageAnnotation
        ]
      },
      dataLabels: {
        enabled: false
      },
      yaxis: {
        labels: {
          formatter: (val: any) => {
            return 'R' + (val).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }

        }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        }
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val: any) {
            if (val) {
              return 'R' + val.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            return;
          }
        }
      }
    };
  }

  clearAnnotations() {
    let annotationBlock = document.querySelectorAll<HTMLElement>('.apexcharts-yaxis-annotations [id^=SvgjsRect]');
    for (var i = 0; i < annotationBlock.length; i++) {
      annotationBlock[i].style.display = "none";
    }

    let annotationLabel = document.querySelectorAll<HTMLElement>('.apexcharts-yaxis-annotation-label');
    for (var i = 0; i < annotationLabel.length; i++) {
      annotationLabel[i].style.display = "none";
    }
  }

  addAnnotations() {
    let annotationBlock = document.querySelectorAll<HTMLElement>('.apexcharts-yaxis-annotations [id^=SvgjsRect]');
    for (var i = 0; i < annotationBlock.length; i++) {
      annotationBlock[i].style.display = "block";
    }

    let annotationLabel = document.querySelectorAll<HTMLElement>('.apexcharts-yaxis-annotation-label');
    for (var i = 0; i < annotationLabel.length; i++) {
      annotationLabel[i].style.display = "block";
    }
  }

  async loadNotificationSettings() {
    const any = 0;
    const priceUp = 1;
    const priceDown = 2;
    const stockChange = 3;
    const lowest = 4;
    const highest = 5;
    const deal = 6;

    let $this = this;
    if (this.userId != null) {
      let notifyTopics = await this.notificationService.getTopics(this.userId, this.productId, this.store);
      if (notifyTopics) {
        notifyTopics.forEach((notifyTopic: any) => {
          if (notifyTopic.type === any) {
            $this.anyPrice = true;
          } else if (notifyTopic.type === priceUp) {
            $this.priceUp = true;
          } else if (notifyTopic.type === priceDown) {
            $this.priceDown = true;
          } else if (notifyTopic.type === stockChange) {
            $this.stockChange = true;
          } else if (notifyTopic.type === lowest) {
            $this.lowestPrice = true;
          }
        })
      }
    }
  }

  priceAnyChange(checked: boolean) {
    if (this.userId) {
      let topics = this.getTopic(NotificationTypes.any);
      if (checked) {
        this.notificationService.subscribeNotification(this.userId, topics.storeProductId, topics.store, topics.type);
      } else {
        this.notificationService.unsubscribeNotification(this.userId, topics.storeProductId, topics.store, topics.type);
      }
    }
  }

  priceUpChange(checked: boolean) {
    if (this.userId) {
      let topics = this.getTopic(NotificationTypes.priceUp);
      if (checked) {
        this.notificationService.subscribeNotification(this.userId, topics.storeProductId, topics.store, topics.type);
      } else {
        this.notificationService.unsubscribeNotification(this.userId, topics.storeProductId, topics.store, topics.type);
      }
    }
  }

  priceDownChange(checked: boolean) {
    if (this.userId) {
      let topics = this.getTopic(NotificationTypes.priceDown);
      if (checked) {
        this.notificationService.subscribeNotification(this.userId, topics.storeProductId, topics.store, topics.type);
      } else {
        this.notificationService.unsubscribeNotification(this.userId, topics.storeProductId, topics.store, topics.type);
      }
    }
  }

  priceLowestChange(checked: boolean) {
    if (this.userId) {
      let topics = this.getTopic(NotificationTypes.lowest);
      if (checked) {
        this.notificationService.subscribeNotification(this.userId, topics.storeProductId, topics.store, topics.type);
      } else {
        this.notificationService.unsubscribeNotification(this.userId, topics.storeProductId, topics.store, topics.type);
      }
    }
  }

  stockStatusChange(checked: boolean) {
    if (this.userId) {
      let topics = this.getTopic(NotificationTypes.stockChange);
      if (checked) {
        this.notificationService.subscribeNotification(this.userId, topics.storeProductId, topics.store, topics.type);
      } else {
        this.notificationService.unsubscribeNotification(this.userId, topics.storeProductId, topics.store, topics.type);
      }
    }
  }


  getTopic(type: NotificationTypes) {
    let notificationItem: any = {};
    notificationItem.store = this.store;
    notificationItem.storeProductId = this.productId;
    notificationItem.type = type.valueOf();
    return notificationItem;
  }

  formatter = new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR'
  });

  formatDate(date: Date | undefined) {
    if (date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }
    return;
  }

  increased(amount: number | undefined) {
    if (amount && amount > 0) {
      return true;
    } else if (amount && amount < 0) {
      return false;
    } else {
      return null;
    }
  }

  sendIframeSize() {
    var height = this.getDocHeight(null);
    window.parent.postMessage(height, "*");
  }

  getDocHeight(doc: any) {
    var doc = doc || document;
    var body = doc.body;
    var height = Math.max(body.scrollHeight, body.offsetHeight);
    return height;
  }
}