<!-- Toolbar-->
<div class="bg-light shadow-lg rounded-3 p-4 mt-n5 mb-4">
  <div class="row gy-3 gx-4 justify-content-between">
    <div class="col-lg-2 col-md-3 col-sm-5 col-12 order-md-1 order-sm-2 order-3">
      <div class="dropdown">
        <button class="btn btn-outline-secondary dropdown-toggle w-100" (click)="collapse.toggle()"
          id="dropdown-filters" href="#shop-filters" data-bs-toggle="collapse">
          <i class="fa fa-filter me-2"></i>Filters
        </button>
      </div>
    </div>
    <div class="col-md col-12 order-md-2 order-sm-1 order-1">
      <div class="input-group">
        <input class="form-control pe-5 rounded" type="text" id="keyword" placeholder="Search title, url or brand..."
          (change)="searchProducts()" [(ngModel)]="search" />
        <i class="fa fa-search position-absolute top-50 end-0 translate-middle-y zindex-5 me-3"></i>
      </div>
    </div>
    <div class="col-lg-2 col-md-3 col-sm-5 col-12 order-md-3 order-sm-3 order-3">
      <div ngbDropdown class="d-inline-block w-100">
        <button class="btn btn-outline-secondary w-100" id="dropdownBasic1" ngbDropdownToggle>Sort</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem (click)="onSortChange(0)">Recent</button>
          <button ngbDropdownItem (click)="onSortChange(1)">Lowest price</button>
          <button ngbDropdownItem (click)="onSortChange(2)">Highest price</button>
          <button ngbDropdownItem (click)="onSortChange(3)">Highest daily discount</button>
          <button ngbDropdownItem (click)="onSortChange(4)">Highest average discount</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Toolbar with expandable filters-->
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" id="shop-filters">
    <div class="row g-4 pt-4">
      <div class="col">
        <!-- Categories-->
        <div class="card">
          <div class="card-body px-4">
            <div class="widget">
              <h3 class="widget-title mb-2 pb-1">Stores</h3>
              <ul class="widget-list list-unstyled">
                <li class="d-flex justify-content-between align-items-center mb-1">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="stores" id="all" value="All"
                      (click)="selectStore(undefined)" />
                    <label class="form-check-label" for="all">All</label>
                  </div>
                </li>
                <li *ngFor="let x of stores" class="d-flex justify-content-between align-items-center mb-1">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="stores" (click)="selectStore(x)"
                      [checked]="x.checked" value="{{ x.store.id }}" id="{{ x.store.name }}" />
                    <label class="form-check-label" for="{{ x.store.name }}">
                      {{ x.store.name }}
                    </label>
                  </div>
                  <span class="fs-xs text-muted">
                    {{ x.store.productCount }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>