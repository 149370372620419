import { Injectable } from '@angular/core';
import { Client, UpdateUserDto, User, VerifyTokenDto } from './client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private client: Client;

  constructor() {
    this.client = new Client(environment.apiUrl);
  }

  async updateUser(body: UpdateUserDto | undefined)  {
    var user = (await this.client.updateUser(body));
    return user;
  }

  async verifyEmail(body: VerifyTokenDto | undefined) {
    var result = (await this.client.verifyEmail(body));
  }
}
