<div class="modal-header">
  <h4 class="modal-title product-title" id="modal-basic-title">
    <a href="{{selectedProduct?.url}}" target="_blank" data-bs-toggle="tooltip" data-bs-placement="right"
      data-bs-original-title="Go to product page">
      {{selectedProduct?.title}}
      <i class="fa fa-caret-right fs-lg ms-2"></i>
    </a>
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <!-- Product gallery-->
    <div class="col-2 pe-lg-0">
      <div class="product-gallery">
        <div class="product-gallery-preview order-sm-2">
          <div class="product-gallery-preview-item active" id="first">
            <img class="image-zoom" src="{{selectedProduct?.imageUrl}}" loading="lazy" onerror="this.src='/assets/products/product-default.jpg';" alt="Product image">
            <div class="image-zoom-pane"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Price stats -->
    <div class="col-lg-5 pt-4 pt-lg-0 image-zoom-pane">
      <div class="product-details ms-auto pb-3">
        <div class="mb-3">
          <span class="h3 fw-normal text-accent me-1">{{selectedProduct?.price}}</span>
          <del class="text-muted fs-lg me-3">{{selectedProduct?.listingPrice}}</del>
          <ng-container *ngIf="selectedProduct?.dailyDiscount && !this.hasIncreased(selectedProduct?.dailyDiscount)">
            <span class="badge bg-success badge-shadow align-middle mt-n2">
              {{selectedProduct?.dailyDiscount}}%
            </span>
          </ng-container>
          <ng-container *ngIf="selectedProduct?.dailyDiscount && this.hasIncreased(selectedProduct?.dailyDiscount)">
            <span class="badge bg-danger badge-shadow align-middle mt-n2">
              {{selectedProduct?.dailyDiscount}}%
            </span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <app-product-detail [productId]="selectedProduct?.storeProductId" [store]="selectedProduct?.storeDomain"
      [userId]="settings.userId" [token]="settings.firebaseToken"></app-product-detail>
  </div>
</div>