<!-- Header-->
<app-page-header [pageHeader]="'Settings'"></app-page-header>

<div class="container p-5 mb-2 mb-md-4">
    <div class="row">
        <div class="col mb-2" (click)="changeNotificationMethod('push')">
            <div class="h-100 card" [ngClass]="{'selected-card': settings.push}">
                <img class="card-img-top" src="/assets/theme/push-notification.png" alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">Push notification</h5>
                    <p class="card-text">Get instant price notifications on your desktop or mobile device with push
                        notifications. Stay up-to-date with the latest prices and never miss a deal.</p>
                    <button *ngIf="settings.push && !settings.firebaseToken" class="btn btn-primary" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please allow notifications on your browser
                    </button>
                    <button *ngIf="settings.push && settings.firebaseToken && !saved" class="btn btn-primary"
                        (click)="saveSettings()">Save</button>
                    <button *ngIf="settings.firebaseToken && settings.push" class="btn btn-secondary float-end"
                        (click)="sendTestPushNotification()">Test Notification</button>
                </div>
            </div>
        </div>
        <div class="col mb-2" (click)="changeNotificationMethod('email')">
            <div class="card" [ngClass]="{'selected-card': settings.email}">
                <img class="card-img-top" src="/assets/theme/email-notification.png" alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">Email notification</h5>
                    <p class="card-text">Receive price notifications via email. Stay informed about the latest prices
                        and never miss a deal. Enter your email address below to get started.</p>
                    <div class="mb-2" *ngIf="settings.email">
                        <input type="email" class="form-control mb-2" placeholder="Enter your email address"
                            [(ngModel)]="settings.emailaddress">
                        <button *ngIf="!saved" class="btn btn-primary" (click)="saveSettings()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col mt-5">
            <h1>FAQ</h1>
            <div>
                <h4 class="mt-4">Not getting notifications?</h4>
                <p>Try clearing site data by clicking the button below and then re-selecting your preferred notification
                    method. You can also confirm that notifications are working by clicking the "Test notification"
                    button.</p>
                <button class="btn btn-primary" (click)="clearSiteData()">Clear site data</button>
            </div>
            <div>
                <h4 class="mt-4">How will I receive notifications?</h4>
                <p>
                    Scrapy uses firebase push notifications to send notifications to your device. You will receive a
                    push notification on your device when a new price is detected.
                </p>
            </div>
        </div>
    </div>