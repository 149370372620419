import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationDto, ProductDisplayDto } from 'src/app/core/services/client.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'src/app/core/services/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  // Settings
  settings: any = {
    userId: null,
    email: false,
    push: false,
    firebaseToken: null,
    emailaddress: null
  };

  // Filters
  search: string | undefined;
  selectedStore: number | undefined;

  // Products
  notifications: NotificationDto[] | undefined = [];
  products: ProductDisplayDto[] = [];
  selectedProduct: ProductDisplayDto | undefined;
  page: number = 1;
  take: number = 20;
  isLoading: boolean = false;

  constructor(
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    // load settings from localstorage
    let settings = window.localStorage.getItem('settings');
    this.settings = settings ? JSON.parse(settings) : this.settings;

    this.loadProducts();
  }

  async loadProducts() {
    this.isLoading = true;
    if (this.settings.firebaseToken) {
      let token = this.settings.firebaseToken;
      let userId = this.settings.userId;
      this.notifications = (await this.notificationsService.getNotifications(userId, undefined)) ?? [];
      this.products = this.notifications
        .filter(n => n.product)
        .map(n => n.product as ProductDisplayDto);
    }
    this.isLoading = false;
  }


  /// Events
  openModal(content: any, product: ProductDisplayDto) {
    this.selectedProduct = product;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', fullscreen: true })
      .result.then();
  }

}
