<ng-template [ngIf]="showLayout">
    <app-navbar></app-navbar>

    <main class="page-wrapper">
        <router-outlet></router-outlet>
    </main>


    <app-footer></app-footer>

</ng-template>

<ng-template [ngIf]="!showLayout">
    <router-outlet></router-outlet>
</ng-template>