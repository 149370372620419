import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  isLoading: boolean = true;
  unsubscribed: boolean = false;

  constructor(private route: ActivatedRoute, private userService: UserService) {

  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(async params => {
        let email = params['email'];

        if (email) {
          this.unsubscribed = await this.userService.unsubscribe(email);
          this.isLoading = false;
        }
      }
      );
  }
}
