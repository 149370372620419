import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about/about.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { StoresComponent } from './stores/stores.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { IframeComponent } from './iframe/iframe.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SettingsComponent } from './settings/settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactComponent } from './contact/contact.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

@NgModule({
  declarations: [
    HomeComponent,
    AboutComponent,
    NotificationsComponent,
    StoresComponent,
    HomeComponent,
    IframeComponent,
    SettingsComponent,
    ContactComponent,
    PrivacyPolicyComponent,
    VerifyEmailComponent,
    UnsubscribeComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, RecaptchaModule, MatSlideToggleModule, MatSnackBarModule, MatProgressSpinnerModule, SharedModule],
  exports: [
    HomeComponent,
    AboutComponent,
    NotificationsComponent,
    StoresComponent,
    SettingsComponent,
    ContactComponent,
    PrivacyPolicyComponent,
    VerifyEmailComponent,
    UnsubscribeComponent,
  ],
})
export class FeaturesModule { }
