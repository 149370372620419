import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './features/about/about.component';
import { NotificationsComponent } from './features/notifications/notifications.component';
import { StoresComponent } from './features/stores/stores.component';
import { HomeComponent } from './features/home/home.component';
import { IframeComponent } from './features/iframe/iframe.component';
import { SettingsComponent } from './features/settings/settings.component';
import { ContactComponent } from './features/contact/contact.component';
import { PrivacyPolicyComponent } from './features/privacy-policy/privacy-policy.component';
import { VerifyEmailComponent } from './features/verify-email/verify-email.component';
import { UnsubscribeComponent } from './features/unsubscribe/unsubscribe.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'home/search', component: HomeComponent },
  { path: 'specials/daily', component: HomeComponent, data: { sort: '3' } },
  { path: 'specials/best', component: HomeComponent, data: { sort: '4' } },
  { path: 'about', component: AboutComponent },
  { path: 'notifications', component: NotificationsComponent },
  { path: 'stores', component: StoresComponent },
  { path: 'iframe', component: IframeComponent },
  { path: 'settings/:token', component: SettingsComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'contact-us', component: ContactComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'verify-email', component: VerifyEmailComponent },
  { path: 'unsubscribe', component: UnsubscribeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
