<section class="container py-4 py-sm-5">
    <div class="row pt-3 pt-sm-0">
        <div *ngFor="let store of stores" class="col-md-4 col-sm-6 mb-grid-gutter">
            <a class="card product-card h-100 border-0 shadow pb-2" [routerLink]="['/']"
                [queryParams]="{store: store.id}">
                <div class="store-card-top rounded-top" [ngStyle]="{'background-color': store.color}"></div>
                <div class="bg-white rounded-top pt-1 px-2 mx-auto mt-n5" style="width: 175px;">
                    <img class="d-block rounded-top mx-auto" src="/assets/store-logos/{{store.logo}}"
                        style="width: auto; max-height: 50px;" alt="Logo">
                </div>
                <div class="card-body text-center pt-3 pb-4">
                    <h3 class="h5 mb-2">
                        {{store.name}}
                    </h3>
                    <div *ngIf="store.productCount" class="fs-ms text-muted">
                        {{store.productCount | number}} products
                        <i *ngIf="store.scannedToday" class="fa fa-check" style="color: #198754!important"></i>
                    </div>
                </div>
            </a>
        </div>
    </div>
</section>