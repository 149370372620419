<div class="row" *ngIf="!isLoading">
    <div class="col text-center mt-5" *ngIf="unsubscribed">
        <h1>Unsubscribe</h1>
        <p>Your have been unsibscribed!</p>
    </div>

    <div class="col text-center mt-5" *ngIf="!unsubscribed">
        <h1>Unsubscribe</h1>
        <p>Unable to unsubscribe your email, please click use the contact form</p>
    </div>
</div>