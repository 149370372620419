import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent {
  productId: string | undefined = undefined;
  store: string | undefined = undefined;
  showSettings: boolean = false;
  userId: string | undefined = undefined;

  constructor(private route: ActivatedRoute,) {
    this.route.queryParamMap.subscribe((params) => {
      this.productId = params.get('productId') ?? undefined;
      this.store = params.get('store') ?? undefined;
      this.showSettings = params.get('settings') === 'true';
      this.userId = params.get('userId') ?? undefined;
    });
  }
}
