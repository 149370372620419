import { Component, Optional } from '@angular/core';
import { Messaging, getToken, onMessage, getMessaging } from '@angular/fire/messaging';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable, from, share, tap } from 'rxjs';
import { NotificationMethods, UpdateUserDto } from 'src/app/core/services/client.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  // Settings
  settings: any = {
    userId: null,
    email: false,
    push: false,
    firebaseToken: null,
    emailaddress: null
  };
  saved: boolean = true;
  isListening: boolean = false;

  // Push notifications
  messaging: Messaging | undefined;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  // FCM
  token$: Observable<any> = EMPTY;
  message$: Observable<any> = EMPTY;


  constructor(private route: ActivatedRoute, @Optional() messaging: Messaging, private snackBar: MatSnackBar, private notificationService: NotificationsService, private userService: UserService) {
    this.messaging = messaging;
  }

  ngOnInit() {
    // load settings from localstorage
    let settingsJson = window.localStorage.getItem('settings');
    if (settingsJson) {
      this.settings = JSON.parse(settingsJson);
    }

    // Set timeout
    setTimeout(() => {
      // Load userId from input - set by extension
      var userId = (<HTMLInputElement>document.getElementById('scrapy-user-input')).value;
      if (userId) {
        this.settings.userId = userId;
      }
    }, 1000);

    // Load token from local storage
    let token = window.localStorage.getItem('token');
    if (token) {
      this.settings.firebaseToken = token;
    }

    // Listen for messages
    if (this.settings?.firebaseToken) {
      let token = this.settings.firebaseToken;
      console.log('FCM', { token })
      this.listen();
    }
  }

  /// Events

  changeNotificationMethod(method: string) {
    if (method == 'email' && !this.settings.email) {
      this.settings.email = true;
      this.settings.push = false;
    }
    if (method == 'push' && !this.settings.push) {
      this.requestPermission();

      this.settings.email = false;
      this.settings.push = true;
    }
    this.saved = false;
  }

  /// Functions

  async saveSettings() {
    let updateUserDto = new UpdateUserDto({
      userIdEncrypted: this.settings.userId,
      email: this.settings.emailaddress,
      firebaseToken: this.settings.firebaseToken,
      notificationMethod: this.settings.email ? NotificationMethods._0 : this.settings.push ? NotificationMethods._1 : undefined
    });

    // create or update user
    const user = await this.userService.updateUser(updateUserDto);
    if (user) {
      this.settings.userId = user.userIdEncrypted;

      // Save settings in local storage
      const settingsString = JSON.stringify(this.settings);
      window.localStorage.setItem('settings', settingsString);

      // Send settings to extension
      window.parent.postMessage(settingsString, "*");

      this.saved = true;
    }
  }

  clearSiteData() {
    sessionStorage.clear();
    localStorage.clear();
    location.reload();
  }

  /// push notifications

  // Request permission
  async requestPermission() {
    if (this.messaging) {
      let serviceWorkerRegistration = await navigator.serviceWorker.register('firebase-messaging-sw.js', { type: 'module', scope: '__' });
      let token = await getToken(this.messaging, { vapidKey: environment.vapidKey, serviceWorkerRegistration });
      if (token) {
        console.log('FCM', { token })
        this.settings.firebaseToken = token;
        this.listen();
      }
    }
  }

  // Listen for messages
  async listen() {
    if (this.messaging && !this.isListening) {
      onMessage(this.messaging, (payload) => {
        let title = payload.notification?.title || 'Test notification';

        // Show notification
        this.snackBar.open(title, 'Close', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      });
      this.isListening = true;
    }
  }

  sendTestPushNotification() {
    this.notificationService.sendTestPushNotification(this.settings.firebaseToken);
  }
}
