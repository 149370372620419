import { Component } from '@angular/core';
import { StoreDto } from 'src/app/core/services/client.service';
import { StoresService } from 'src/app/core/services/stores.service';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})
export class StoresComponent {
  stores: StoreDto[] = [];

  constructor(private storeService: StoresService) {
    this.loadProducts();
  }

  async loadProducts() {
    this.stores = await this.storeService.getStores() ?? [];
  }
}
