import { Injectable } from '@angular/core';
import { Client, ContactDto } from './client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private client: Client;

  constructor() {
    this.client = new Client(environment.apiUrl);
  }

  async sendContact(body: ContactDto | undefined) {
    await this.client.send(body);
  }
}
