import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductDisplayDto } from 'src/app/core/services/client.service';
import { ProductsService } from 'src/app/core/services/products.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Messaging, getToken, onMessage } from '@angular/fire/messaging';
import { EMPTY, Observable, from, share, tap } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  // Page variables
  scrollThreshold = 200; // px
  showBackToTop = false;

  // Filters
  store: number | undefined;
  search: string | undefined;
  sort: number | undefined;

  // Products
  products: ProductDisplayDto[] = [];
  selectedProduct: ProductDisplayDto | undefined;
  page: number = 1;
  take: number = 20;
  isLoading: boolean = false;
  paging: boolean = false;
  isMore: boolean = true;

  // FCM
  token$: Observable<any> = EMPTY;
  message$: Observable<any> = EMPTY;


  constructor(
    private productService: ProductsService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private messaging: Messaging
  ) {
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      this.search = params.get('q') ?? undefined;
      this.store = params.get('store') ? Number(params.get('store')) : undefined;
      this.sort = params.get('sort') ? Number(params.get('sort')) : this.route.snapshot.data['sort'] ?? 0;
      this.page = 1;
      this.loadProducts(false);
    });
  }

  async loadProducts(append: boolean) {
    this.isLoading = true;
    var products: ProductDisplayDto[] = [];
    products = (await this.productService.searchProducts(this.search, this.page, this.take, this.store, this.sort)) ?? [];
    this.isLoading = false;
    this.paging = false;
    this.isMore = products.length >= this.take;

    if (append) {
      this.products.push(...products);
    } else {
      this.products = products;
    }
  }


  /// Events

  searchProducts(search: string | undefined) {
    this.page = 1;
    this.search = search;
    this.loadProducts(false);
  }

  openModal(content: any, product: ProductDisplayDto) {
    this.selectedProduct = product;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', fullscreen: true })
      .result.then();
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    if (this.isMore) {
      var productCard = document.getElementsByClassName('products-container')[0];
      var scrollHeight = productCard?.scrollHeight;
      var windowScroll = window.scrollY;
      if (Math.floor(windowScroll) >= Math.floor(scrollHeight - 1000) && !this.paging) {
        this.paging = true;
        this.page++;
        this.loadProducts(true);
      }
      if (windowScroll >= this.scrollThreshold) {
        this.showBackToTop = true;
      } else {
        this.showBackToTop = false;
      }
    }
  }

  backToTop() {
    window.scrollTo(0, 0);

  }
}
