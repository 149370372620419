import { StoresService } from 'src/app/core/services/stores.service';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { searchStore } from 'src/app/types/search-stores';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnChanges {
  // Variables
  @Input() store: number | undefined;
  @Input() search: string | undefined;
  @Input() sort: number | undefined = 0;

  stores: searchStore[] = [];
  public isCollapsed = true;

  constructor(private storeService: StoresService, private router: Router,) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadStores();
  }

  /// API Methods

  async loadStores() {
    this.stores = ((await this.storeService.getStores()) ?? []).map((obj) => {
      var selected = this.store == obj.id;
      return <searchStore>{
        store: obj,
        checked: selected,
      };
    });

    var selectedStore = this.stores.find(x => x.checked);
    if (selectedStore) {
      this.selectStore(selectedStore);
    }
  }

  /// Events

  selectStore(searchStore: searchStore | undefined) {
    // Uncheck all stores
    this.stores.forEach((x) => (x.checked = false));
    // Check selected store
    if (searchStore) {
      searchStore.checked = true;
    }
    // Set store filter
    this.store = searchStore?.store.id;
    // Collapse store filter
    this.isCollapsed = true;
    this.searchProducts();
  }

  onSearchChange(event: any): void {
    this.search = event.target.value;
    this.searchProducts();
  }

  onSortChange(sort: number): void {
    this.sort = sort;
    this.searchProducts();
  }

  searchProducts() {
    this.router.navigate(['/'], { queryParams: { store: this.store, q: this.search, sort: this.sort } })
  }
}
