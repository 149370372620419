<!-- <input type="hidden" id="store" value="{{ store }}" />
<input type="hidden" id="product-id" value="{{ productId }}" />
<input type="hidden" id="show-list-price" value="{{ showListPrice }}" /> -->

<div class="nav-wrapper m-2">
  <ul ngbNav #nav="ngbNav" class="nav nav-pills nav-fill flex-column flex-sm-row" orientation="horizontal"
    (navChange)="sendIframeSize()">
    <ng-container ngbNavItem="graph">
      <li class="nav-item">
        <button ngbNavLink class="nav-link mb-sm-3 mb-md-0">
          <i class="fa fa-chart-line mr-2"></i>Graph
        </button>
      </li>
      <ng-template ngbNavContent>
        <ng-template [ngIf]="!isLoading">
          <div id="priceGraph" (mouseenter)="clearAnnotations()" (mouseleave)="addAnnotations()">
            <apx-chart #chart [autoUpdateSeries]="true" [series]="chartOptions.series" [chart]="chartOptions.chart"
              [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis" [stroke]="chartOptions.stroke"
              [tooltip]="chartOptions.tooltip" [dataLabels]="chartOptions.dataLabels"
              [annotations]="chartOptions.annotations"></apx-chart>
          </div>
        </ng-template>
      </ng-template>
    </ng-container>

    <ng-container ngbNavItem="stats">
      <li class="nav-item">
        <button ngbNavLink><i class="fa fa-percentage mr-2"></i>Stats</button>
      </li>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col-md-12">
            <ul class="list-group">
              <li class="list-group-item">
                <div class="row">
                  <div class="col-md-3">Current</div>
                  <div class="col-md-3">
                    <span *ngIf="current?.date" class="badge rounded-pill bg-dark float-right" id="current-price-date">
                      {{current?.date | date: 'dd/MM/yyyy'}}
                    </span>
                  </div>
                  <div class="col-md-3"></div>
                  <div class="col-md-3">
                    <span class="badge rounded-pill bg-info float-right" id="current-price">
                      {{current?.amount | currency:'ZAR': 'symbol-narrow'}}
                    </span>
                  </div>
                </div>
              </li>
              <li class="list-group-item justify-content-between align-items-center">
                <div class="row">
                  <div class="col-md-3">Highest</div>
                  <div class="col-md-3">
                    <span *ngIf="highest?.date" class="badge rounded-pill bg-dark float-right" id="highest-price-date">
                      {{highest?.date | date: 'dd/MM/yyyy'}}
                    </span>
                  </div>
                  <div class="col-md-3">
                    <span class="badge rounded-pill float-right" id="highest-price-percentage"
                      [ngClass]="{'bg-danger': increased(highest?.percentage), 'bg-success' : !increased(highest?.percentage), 'bg-dark' : increased(highest?.percentage) == null}">
                      {{highest?.percentage}} %
                    </span>
                  </div>
                  <div class="col-md-3">
                    <span class="badge bg-info rounded-pill float-right" id="highest-price">
                      {{highest?.amount | currency:'ZAR': 'symbol-narrow'}}
                    </span>
                  </div>
                </div>
              </li>
              <li class="list-group-item justify-content-between align-items-center">
                <div class="row">
                  <div class="col-md-3">Average</div>
                  <div class="col-md-3">
                    <span *ngIf="average?.date" class="badge rounded-pill bg-dark float-right" id="average-price-date">
                      {{average?.date | date: 'dd/MM/yyyy'}}
                    </span>
                  </div>
                  <div class="col-md-3">
                    <span class="badge rounded-pill float-right" id="average-price-percentage"
                      [ngClass]="{'bg-danger': increased(average?.percentage), 'bg-success' : !increased(average?.percentage), 'bg-dark' : increased(average?.percentage) == null}">
                      {{average?.percentage}} %
                    </span>
                  </div>
                  <div class="col-md-3">
                    <span class="badge bg-info rounded-pill float-right" id="average-price">
                      {{average?.amount | currency:'ZAR': 'symbol-narrow'}}
                    </span>
                  </div>
                </div>
              </li>
              <li class="list-group-item justify-content-between align-items-center">
                <div class="row">
                  <div class="col-md-3">Lowest</div>
                  <div class="col-md-3">
                    <span *ngIf="lowest?.date" class="badge rounded-pill bg-dark float-right" id="lowest-price-date">
                      {{lowest?.date | date: 'dd/MM/yyyy'}}
                    </span>
                  </div>
                  <div class="col-md-3">
                    <span class="badge rounded-pill float-right" id="lowest-price-percentage"
                      [ngClass]="{'bg-danger': increased(lowest?.percentage), 'bg-success' : !increased(lowest?.percentage), 'bg-dark' : increased(lowest?.percentage) == null}">
                      {{lowest?.percentage}} %
                    </span>
                  </div>
                  <div class="col-md-3">
                    <span class="badge bg-info rounded-pill float-right" id="lowest-price">
                      {{lowest?.amount | currency:'ZAR': 'symbol-narrow'}}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <ng-container ngbNavItem="notify">
      <li class="nav-item">
        <button ngbNavLink><i class="fa fa-bell mr-2"></i>Notify</button>
      </li>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col-md-12" *ngIf="!showSettings">
            <ul class="list-group" id="notifications-div">
              <li class="list-group-item justify-content-between align-items-center">
                <div class="row">
                  <div class="col-md-4">
                    <label for="notify-any">Any price changes</label>
                  </div>
                  <div class="col-md-4"></div>
                  <div class="col-md-4">
                    <div class="form-check form-switch">
                      <input [(ngModel)]="anyPrice" (change)="priceAnyChange(anyPrice)" class="form-check-input"
                        type="checkbox" id="notify-any">
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item justify-content-between align-items-center">
                <div class="row">
                  <div class="col-md-4">
                    <label for="notify-up">Price up</label>
                  </div>
                  <div class="col-md-4"></div>
                  <div class="col-md-4">
                    <div class="form-check form-switch">
                      <input [(ngModel)]="priceUp" (change)="priceUpChange(priceUp)" class="form-check-input"
                        type="checkbox" id="notify-up">
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item justify-content-between align-items-center">
                <div class="row">
                  <div class="col-md-4">
                    <label for="notify-down">Price down</label>
                  </div>
                  <div class="col-md-4"></div>
                  <div class="col-md-4">
                    <div class="form-check form-switch">
                      <input [(ngModel)]="priceDown" (change)="priceDownChange(priceDown)" class="form-check-input"
                        type="checkbox" id="notify-down">
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item justify-content-between align-items-center">
                <div class="row">
                  <div class="col-md-4">
                    <label for="notify-lowest">Lowest price</label>
                    <span class="fa fa-info-circle mx-2" data-toggle="tooltip" data-placement="right"
                      title="Notify when the product is at the lowest price it has ever been"></span>
                  </div>
                  <div class="col-md-4"></div>
                  <div class="col-md-4">
                    <div class="form-check form-switch">
                      <input [(ngModel)]="lowestPrice" (change)="priceLowestChange(lowestPrice)"
                        class="form-check-input" type="checkbox" id="notify-lowest">
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item justify-content-between align-items-center">
                <div class="row">
                  <div class="col-md-4">
                    <label for="notify-stock">Stock status change</label>
                  </div>
                  <div class="col-md-4"></div>
                  <div class="col-md-4">
                    <div class="form-check form-switch">
                      <input [(ngModel)]="stockChange" (change)="stockStatusChange(stockChange)"
                        class="form-check-input" type="checkbox" id="notify-stock">
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="col-md-12 text-center mt-3" *ngIf="showSettings">
            <h1>Notification settings</h1>
            <p>Please specify your preferred notification method.</p>
            <button class="btn btn-primary m-1" *ngIf="!showReload" (click)="gotoSettings()">
              <i class="fas fa-cog"></i> Settings
            </button>
            <button class="btn btn-secondary m-1" *ngIf="showReload" (click)="reload()">
              <i class="fas fa-sync-alt"></i> Reload
            </button>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <li>
      <a href="https://www.buymeacoffee.com/scrapy" target="_blank">
        <img style="width: 100px;" class="coffee" src="/assets/theme/coffee.png" />
      </a>
    </li>
  </ul>
</div>
<div class="card shadow m-2">
  <div class="card-body">
    <div [ngbNavOutlet]="nav"></div>
    <a href="https://scrapy.co.za/" target="_blank">
      <img class="scrapy-logo" src="/assets/logo/logo.png" />
    </a>
  </div>
</div>