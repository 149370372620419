<div class="col-lg-3 col-md-4 col-sm-6 mb-grid-gutter w-100 h-100">
    <article class="card h-100 product-card border-0 shadow pb-2" data-id="{{product?.storeProductId}}">
        <a class="d-block text-center shop-product-details" (click)="productClick()" data-bs-toggle="modal">
            <img loading="lazy" onerror="this.src='/assets/products/product-default.jpg';" src="{{product?.imageUrl}}"
                alt="{{product?.title}}" />
        </a>
        <ng-template [ngIf]="product?.averageDiscount && !this.hasIncreased(product?.averageDiscount)">
            <div class="badge bg-success m-3 fs-sm position-absolute top-0 start-0 zindex-5 average-discount">
                {{product?.averageDiscount}}%
            </div>
        </ng-template>
        <ng-template [ngIf]="product?.averageDiscount && this.hasIncreased(product?.averageDiscount)">
            <div class="badge bg-danger m-3 fs-sm position-absolute top-0 start-0 zindex-5 average-discount">
                {{product?.averageDiscount}}%
            </div>
        </ng-template>

        <ng-template [ngIf]="product?.dailyDiscount && !this.hasIncreased(product?.dailyDiscount)">
            <div class="badge bg-success m-3 fs-sm position-absolute top-0 start-0 zindex-5 daily-discount">
                Daily discount: {{product?.dailyDiscount}}%
            </div>
        </ng-template>
        <ng-template [ngIf]="product?.dailyDiscount && this.hasIncreased(product?.dailyDiscount)">
            <div class="badge bg-danger m-3 fs-sm position-absolute top-0 start-0 zindex-5 daily-discount">
                Daily discount: {{product?.dailyDiscount}}%
            </div>
        </ng-template>

        <div class="card-body" (click)="productClick()">
            <h3 class="product-title mb-2 fs-base">
                <span class="d-block text-truncate">{{product?.title}}</span>
            </h3>
            <span class="fs-sm text-muted">{{product?.brand}}</span>
            <div class="d-flex align-items-center flex-wrap">
                <h4 class="mt-1 mb-0 fs-base text-darker">{{product?.price}}</h4><del
                    class="mt-1 ms-1 fs-xs text-muted">{{product?.listingPrice}}</del>
            </div>
        </div>
        <div class="card-footer mt-n1 py-0">
            <div class="d-flex align-items-center position-relative mb-1 py-3" style="border-top:4px solid #e3e9ef">
                <img class="me-2" width="80px" src="/assets/store-logos/{{product?.storeImageUrl}}"
                    alt="{{product?.store}}" />
            </div>
        </div>
        <div class="card-footer card-body-hidden">
            <a class="btn btn-primary btn-sm d-block w-100 mb-2 shop-product-details" target="_blank"
                href="{{product?.url}}">
                <i class="fa fa-eye fs-sm me-1"></i>View in store
            </a>
        </div>
    </article>
</div>