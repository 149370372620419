<!-- Header-->
<app-page-header [pageHeader]="'Contact'"></app-page-header>

<div class="container mt-3">
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" *ngIf="!submitted">
        <div class="form-group  mt-3">
            <label for="name">Name</label>
            <input type="text" class="form-control" id="name" formControlName="name" placeholder="Enter your name"
                [ngClass]="{'is-invalid': submitted && contactForm.controls['name'].errors}">
            <div *ngIf="submitted && contactForm.controls['name'].errors" class="invalid-feedback">
                <div *ngIf="contactForm.controls['name'].errors['required']">Name is required</div>
            </div>
        </div>
        <div class="form-group  mt-3">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" formControlName="email" placeholder="Enter your email"
                [ngClass]="{'is-invalid': submitted && contactForm.controls['email'].errors}">
            <div *ngIf="submitted && contactForm.controls['email'].errors" class="invalid-feedback">
                <div *ngIf="contactForm.controls['email'].errors['required']">Email is required</div>
                <div *ngIf="contactForm.controls['email'].errors['email']">Email must be a valid email address</div>
            </div>
        </div>
        <div class="form-group  mt-3">
            <label for="message">Message</label>
            <textarea class="form-control" id="message" formControlName="message" rows="5"
                placeholder="Enter your message"
                [ngClass]="{'is-invalid': submitted && contactForm.controls['message'].errors}"></textarea>
            <div *ngIf="submitted && contactForm.controls['message'].errors" class="invalid-feedback">
                <div *ngIf="contactForm.controls['message'].errors['required']">Message is required</div>
            </div>
        </div>

        <div class="form-group mt-3">
            <re-captcha (resolved)="handleRecaptchaResolved($event)" siteKey="{{recaptchaKey}}"></re-captcha>
        </div>

        <div class="form-group mt-3">
            <button type="submit" [disabled]="!buttonEnabled" class="btn btn-primary">Submit</button>
        </div>
    </form>
    <div *ngIf="submitted">
        <div class="alert alert-success text-center mt-5" role="alert">
            <h4 class="alert-heading">Thank you!</h4>
            <p>Your message has been sent.</p>
        </div>
    </div>
</div>