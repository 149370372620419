<header class="bg-light shadow-sm navbar-sticky" [ngClass]="{'staging': isStaging}">
  <div class="navbar navbar-expand-lg navbar-light">
    <div class="container">
      <a class="navbar-brand d-none d-sm-block me-4 order-lg-1" routerLink="/">
        <img src="/assets/logo/logo.png" width="142" alt="Scrapy">
      </a>
      <a class="navbar-brand d-sm-none me-2 order-lg-1" routerLink="/">
        <img src="/assets/logo/logo.png" width="74" alt="Scrapy">
      </a>
      <div class="collapse navbar-collapse me-auto order-lg-2" [ngClass]="{show : isCollapsed}" id="navbarCollapse">
        <!-- Primary menu-->
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" routerLink="/">Home</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Specials</a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" routerLink="/specials/daily" routerLinkActive="active">Daily deals</a></li>
              <!-- <li><a class="dropdown-item" routerLink="/specials/best">Best deals</a></li> -->
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/stores" routerLinkActive="active">Stores</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" routerLink="/notifications"
              routerLinkActive="active">Notifications</a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" routerLink="/settings" routerLinkActive="active">Settings</a></li>
            </ul>
          </li>
          <li class="nav-item ">
            <a class="nav-link" routerLink="/about" routerLinkActive="active">About</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link" routerLink="/contact-us" routerLinkActive="active">Contact</a>
          </li>
        </ul>
      </div>
      <div class="navbar-toolbar d-flex align-items-center order-lg-3">
        <a href="https://www.buymeacoffee.com/scrapy" target="_blank">
          <img class="coffee" src="/assets/theme/coffee.png">
        </a>
        <button class="navbar-toggler border-0" type="button" (click)="toggleNav()" data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </div>
  </div>
</header>