<!-- Header-->
<app-page-header [pageHeader]="'Recent products'"></app-page-header>

<div class="container pb-5 mb-2 mb-md-4">
  <app-search [store]="store" [search]="search" [sort]="sort"></app-search>



  <!-- Products grid-->
  <ng-template [ngIf]="products.length > 0">
    <div class="row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3 pt-sm-3 products-container">
      <app-product-card *ngFor="let product of products" [product]="product"
        (productClickEvent)="openModal(content, product)"></app-product-card>
    </div>
  </ng-template>

  <!-- No products found -->
  <ng-template [ngIf]="!isLoading && products.length <= 0">
    <div class="col-12 mt-5">
      <div class="alert alert-info text-center" role="alert">
        <i class="fa-solid fa-info-circle m-2"></i>
        <label>No products found</label>
      </div>
    </div>
  </ng-template>

  <!-- Loading indicator -->
  <ng-template [ngIf]="isLoading">
    <div class="col-12 mt-5">
      <div class="alert alert-info text-center" role="alert">
        <i class="fa-solid fa-spinner fa-spin m-2"></i>
        <br />
        <label>Loading...</label>
      </div>
    </div>
  </ng-template>

  <!-- Back to top -->
  <button class="btn btn-scroll-top" [class.show]="showBackToTop" (click)="backToTop()">
    <i class="fa-solid fa-arrow-up"></i>
  </button>
</div>

<!-- Product details modal -->
<ng-template #content let-modal>
  <app-product-modal [modal]="modal" [selectedProduct]="selectedProduct"></app-product-modal>
</ng-template>