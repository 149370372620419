import { Component, Input } from '@angular/core';
import { ProductDisplayDto } from 'src/app/core/services/client.service';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss']
})

export class ProductModalComponent {
  @Input() selectedProduct: ProductDisplayDto | undefined;
  @Input() modal: any;

  settings: any = {
    userId: null,
    email: false,
    push: false,
    firebaseToken: null,
    emailaddress: null
  };

  constructor() {
    let settings = window.localStorage.getItem('settings');
    this.settings = settings ? JSON.parse(settings) : this.settings;
  }

  hasIncreased(number: string | undefined): boolean {
    return number?.includes('+') ? true : false;
  }
}
