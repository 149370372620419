<main class="container-fluid px-0" id="about-page">
    <!-- Extensions -->
    <section class="container py-3 py-lg-5 mt-4 mb-3 text-center">
        <h2 class="h3 my-2">Browser extensions</h2>
        <p class="fs-sm text-muted">Download the browser extension to show the price history on your favorite stores</p>
        <div class="row pt-3">
            <div class="col-6 mb-grid-gutter">
                <div class="d-flex">
                    <a href="https://chrome.google.com/webstore/detail/scrapy/fnjpinddheahjajnledccahpeckhfkia"
                        target="_blank">
                        <img class="browser-image" title="chrome" src="/assets/theme/chrome.png" />
                    </a>
                </div>
            </div>
            <div class="col-6 mb-grid-gutter">
                <div class="d-flex">
                    <a href="https://addons.mozilla.org/en-US/firefox/addon/scrapy/" target="_blank">
                        <img class="browser-image" title="firefox" src="/assets/theme/firefox.png" />
                    </a>
                </div>
            </div>
        </div>
    </section>
    <hr>
    <!-- Android -->
    <section class="row g-0 p-5">
        <div class="col-md-6 bg-position-center order-md-2">
            <div class="phone-frame">
                <img class="phone-image" src="/assets/theme/iphone-frame.png" />
                <video class="phone-video" preload="auto" height="480" controls>
                    <source src="/assets/videos/android.mp4"
                        type='video/mp4'>
                </video>
            </div>
        </div>
        <div class="col-md-6 px-3 px-md-5 py-5">
            <div class="mx-auto py-lg-5" style="max-width: 35rem;">
                <h2 class="h3 pb-3">Android</h2>
                <p class="fs-sm pb-3 text-muted">
                    Search for product history on android
                </p>
                <ol>
                    <li>Open the scrapy website on your mobile device</li>
                    <li>Click the install popup or open the settings menu and click "install app"</li>
                    <li>Once the app has been installed, you can search for product history by opening the product web
                        page or app and clicking the share button.</li>
                    <li>Select Scrapy from the options</li>
                </ol>
            </div>
        </div>
    </section>
    <hr>
    <!-- iPhone -->
    <section class="row g-0 p-5">
        <div class="col-md-6 bg-position-center order-md-2">
            <div class="phone-frame">
                <img class="phone-image" src="/assets/theme/iphone-frame.png" />
                <video class="phone-video" preload="auto" height="480" controls>
                    <source src="/assets/videos/iPhone.mp4"
                        type='video/mp4'>
                </video>
            </div>
        </div>
        <div class="col-md-6 px-3 px-md-5 py-5 order-md-1">
            <div class="mx-auto py-lg-5" style="max-width: 35rem;">
                <h2 class="h3 pb-3">iPhone</h2>
                <p class="fs-sm pb-3 text-muted">
                    Search for product history on iPhone
                </p>
                <ol>
                    <li><a href="https://www.icloud.com/shortcuts/cc3f875e59504ea49aaabdc860c96197"
                            target="_blank">Click here to install the iPhone shortcut</a></li>
                    <li>Visit the product page</li>
                    <li>Click the share button and select "Scrapy - Price history"</li>
                </ol>
            </div>
        </div>
    </section>
    <hr>
</main>