import { Injectable } from '@angular/core';
import { Client, NotificationType } from './client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private client: Client;

  constructor() {
    this.client = new Client(environment.apiUrl);
  }

  async getNotifications(userId: string | undefined, storeId: number | undefined) {
    var notifications = (await this.client.getNotifyProducts(userId, storeId));
    return notifications;
  }

  async getTopics(userId: string | undefined, productId: string | undefined, store: string | undefined) {
    var topics = await this.client.getTopics(userId, productId, store);
    return topics;
  }

  async subscribeNotification(userId: string | undefined, notification_StoreProductId: string | undefined, notification_Store: string | undefined, notification_Type: NotificationType | undefined) {
    var result = (await this.client.subscribeTopic(userId, notification_StoreProductId, notification_Store, notification_Type));
    return result;
  }

  async unsubscribeNotification(userId: string | undefined, notification_StoreProductId: string | undefined, notification_Store: string | undefined, notification_Type: NotificationType | undefined) {
    var result = (await this.client.unsubscribeTopic(userId, notification_StoreProductId, notification_Store, notification_Type));
    return result;
  }

  async sendTestPushNotification(token: string | undefined) {
    var result = (await this.client.testPushNotification(token));
    return result;
  }
}
