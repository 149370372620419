import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactDto } from 'src/app/core/services/client.service';
import { ContactService } from 'src/app/core/services/contact.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  recaptchaKey = environment.reCAPTCHA;

  contactForm: FormGroup;
  submitted: boolean = false;
  buttonEnabled = false;

  constructor(private fb: FormBuilder, private contactService: ContactService) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.submitted = true;
      let contactData = new ContactDto({
        name: this.contactForm.value.name,
        email: this.contactForm.value.email,
        message: this.contactForm.value.message
      });

      // Submit form
      this.contactService.sendContact(contactData).then(() => {
        this.contactForm.reset();
        this.buttonEnabled = false;
        this.contactForm.markAsUntouched();
        this.contactForm.markAsPristine();
        this.contactForm.updateValueAndValidity();
      });
    } else {
      // Mark all form controls as touched to show validation errors
      Object.values(this.contactForm.controls).forEach(control => {
        control.markAsTouched();
      });
    }
  }

  handleRecaptchaResolved(event: any) {
    this.contactForm.patchValue({ recaptcha: event });
    this.buttonEnabled = true;
  }
}
