import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VerifyTokenDto } from 'src/app/core/services/client.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  constructor(private route: ActivatedRoute, private userService: UserService) {

  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        let email = params['email'];
        let token = params['token'];

        if (email && token) {
          let data = new VerifyTokenDto({
            email: email,
            token: token
          });
          this.userService.verifyEmail(data);
        }
      }
      );
  }
}
