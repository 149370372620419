export const environment = {
  environment: 'stage',
  apiUrl: 'https://api-staging.scrapy.co.za',
  firebase: {
    projectId: 'scrapy-df3a7',
    appId: '1:1094257049632:web:3a588b884ce8defbe4bc45',
    storageBucket: 'scrapy-df3a7.appspot.com',
    apiKey: 'AIzaSyBR4FzlwK14oz8OZt28_n_h5SOAlp9Lgfg',
    authDomain: 'scrapy-df3a7.firebaseapp.com',
    messagingSenderId: '1094257049632',
  },
  vapidKey: "BL-evxf_Z6EF7X7lcRU96HdSUNxy6PrJabOKFr6oVWMQTWIVuMHvOGHpjbzuW2X5Ib_cke1dH4tX6CT1BfpFBvs",
  reCAPTCHA: "6LfpEuooAAAAAOnXl6WjPTiwIiv2yxjIQsjMJGqT"
};
